import React from "react"
import { Link } from "gatsby"
import { Container } from "../components/Container"
import { Images } from "./Images"

export const WtpContent = () => {
  return (
    <section className="">
      <Container>
        <div className="services-content-wrapper">
          <p className="">
            Environmental Solutions serve the needs of diverse industries across
            water treatment and demineralization needs with quality products.
            Our team of highly qualified professionals is committed to creating
            solutions that truly meet your objectives.
          </p>
          <p>
            We are proud of our enviable reputation in delivering the highest
            quality water treatment systems. Our products are used to treat all
            types of water problems and have been employed successfully for a
            variety of residential, commercial and industrial applications.
          </p>
          <p>
            The wide range of our products offers people the opportunity to
            better their health and lifestyle with purified, improved water at
            any budget. Our water treatment plants have long life and provide
            possibly the best value for money.
          </p>
          <p>
            To know more,{" "}
            <Link to="/contact-us" className="text-secondary underline">
              contact us.
            </Link>
          </p>
        </div>
        <section className="mb-10">
          <div className="font-sans font-bold text-primary text-xl mb-4 text-center">
            Gallery
          </div>
          <Images
            className="flex flex-wrap p-4 justify-center"
            filterByText="wtp"
            imageClassName="w-32 m-1 cursor-pointer hover:shadow-lg"
            enableGallery={true}
          />
        </section>
      </Container>
    </section>
  )
}
