import React from "react"
import { Header } from "../components/HeaderSection"
import { ServicesMenu } from "../components/ServicesMenu"
import { WtpContent } from "../components/WtpContent"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { AnimationWrapper } from "../components/AnimationWrapper"
import { SplitLetters } from "../components/SplitLetters"

const WaterTreatment = ({ uri, data }) => {
  return (
    <section className="bg-body-lightest">
      <SEO
        title="Water treatment plants (WTP)"
        description="water treatment filters and plants with long life and best value for money"
      />
      <Header />
      <section className="relative mb-6 md:mb-16">
        <Img
          className="h-72"
          fluid={data.placeholderImage.childImageSharp.fluid}
        />
        <AnimationWrapper animationType="fadeIn" delay="0">
          <div className="h-72 absolute inset-0 bg-black-50 flex items-center justify-center">
            <h1 className="services-section--header">
              <SplitLetters text="Water Treatment Plants" />
            </h1>
          </div>
        </AnimationWrapper>
      </section>
      <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-between lg:items-start">
        <ServicesMenu uri={uri} />
        <WtpContent />
      </div>
      <Footer />
    </section>
  )
}

export default WaterTreatment

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "wtp_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
